<template>
  <div>
    <!-- 大图部分 -->
    <BigBgimg :list="bigData"></BigBgimg>
    <!-- 中间内容部分 -->
    <div class="conter">
      <!-- 面包屑导航部分 -->
      <BreadNav></BreadNav>
      <!-- 列表部分 -->
      <div class="nav_content">
        <!-- 左侧标题 -->
        <ul class="left">
          <li
            :class="index == selectId ? 'select_li' : ''"
            v-for="(item, index) in leftList"
            :key="index"
            @click="show(item, index)"
          >
            {{ item.title }}
          </li>
        </ul>
        <!-- 右侧内容 -->
        <div class="right">
          <h3>{{ showItem.title }}</h3>
          <p>{{ showItem.text }}</p>
          <div class="imgBox">
            <img :src="showItem.img" />
          </div>
        </div>
      </div>
    </div>
    <!-- 技术特色 -->
    <div class="features">
      <Mytitle
        :data="{
          h: '技术特色',
          p: '性能优异，超高并发互动，毫秒级延时保证实时性',
        }"
      ></Mytitle>

      <div class="fea_item">
        <div class="item" v-for="(item, index) in feaList" :key="index">
          <img :src="item.img" alt="" />
          <h3>{{ item.title }}</h3>
          <p>{{ item.text }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadNav from "@/components/BreadNav/index"; //面包屑导航
import BigBgimg from "@/components/BigBgimg/index"; //大图
import Mytitle from "@/components/Mytitle/index"; //标题
/* import bus from '@/util/bus' //传参 */
export default {
  components: { BreadNav, BigBgimg, Mytitle },
  data() {
    return {
      // 大图数据
      bigData: {
        topTitle: "核心技术",
        topText:
          "技术驱动产品，只有不断研发、改进的技术才能让产品保持核心竞争力",
        topBtn: "联系我们",
        topBg: require("@/assets/product/core/核心技术首图bg.png"),
      },
      //左侧导航数据
      leftList: [
        {
          title: "人脸检测",
          text: "我们的人脸检测系统可以实时高效检测视频中的多张人脸，精准定位画面中的人脸位置并获取相关信息。人脸检测作为人脸识别系统或相关产品的基础核心技术，必须具备高效精准的性能。拓幻科技提供满足多样场景多种平台的不同性能需求的人脸检测技术，让您的产品获得可靠优质的技术支持。",
          img: require("@/assets/product/core/核心技术--人脸检测.png"),
        },
        {
          title: "人脸关键点",
          text: "我们的人脸关键点识别和追踪技术是基于深度学习框架和图形学优化算法综合研发的成果，可以精准、高效的识别人脸相应数量的关键点并进行实时追踪。在各个平台均具有优良的性能表现。该技术可以满足多种场景下基于人脸关键点识别和追踪的软硬件产品二次开发，让您轻松跨越人脸识别的技术壁垒，实现更高科技的计算机产品开发。",
          img: require("@/assets/product/core/核心技术--人脸关键点.png"),
        },
        {
          title: "姿势估计",
          text: "我们的人脸姿势估计技术在人脸检测和关键点识别基础之上通过精密的算法实现，可以精准计算人脸的三维偏转角度（即欧拉角），包括YAW，PITCH，ROW三个方向的角度偏转，从而获取人脸姿势判定。该技术可用于人脸贴纸、3D模型渲染、人机交互或其它图像处理。  ",
          img: require("@/assets/product/core/核心技术--姿势估计.png"),
        },
        {
          title: "表情识别",
          text: "我们的人脸表情识别技术可以实时识别面部表情动作，包括睁眼闭眼、张嘴闭嘴、挑眉、点头摇头；后续升级版本中会添加面部心情识别，包括开心、伤心等高级功能。这些功能可以用做实现智能人机交互系统、泛娱乐应用、活体检测等功能。",
          img: require("@/assets/product/core/核心技术--表情识别.png"),
        },
        {
          title: "图像处理",
          text: "我们拥有出色的图像算法工程师团队和UI交互设计师团队。目前可提供的技术产品包括实时趣味人脸互换、背景抠图等泛娱乐应用。我们还有更多好玩有趣的图像处理技术产品正在如火如荼的研发中，您可以关注我们的公众号或者官方微博及时获取我们的产品更新信息。",
          img: require("@/assets/product/core/核心技术--图像处理.png"),
        },
        {
          title: "身份证识别",
          text: "我们拥有出色的模式识别算法工程师团队和人工智能算法工程师。目前模版识别技术产品主要以身份证识别，不良信息过滤为核心。很快您将体验到我们最新的身份证识别技术和不良信息过滤系统。您可以关注我们的公众号或者官方微博及时获取我们的产品更新信息。",
          img: require("@/assets/product/core/核心技术--身份证识别.png"),
        },
        {
          title: "人脸比对",
          text: "我们的人脸比对技术可实现一对一、多对一、多对多的人脸相似度比对，兼顾视频和照片应用场景，提供多种平台API，让您轻松将人脸比对的技术应用于各类场景中，包括人证校验系统、人脸考勤系统、门禁系统、人脸记录系统等。让您的应用更智能，让我们的生活更智能。",
          img: require("@/assets/product/core/核心技术--人脸比对.png"),
        },
        {
          title: "2D动态贴纸",
          text: "2D图形渲染技术最直接的应用场景为人脸2D贴纸特效、美妆特效、美颜滤镜、哈哈镜等，可轻松满足直播推流、连麦、短视频、美颜相机等应用需求，让您的产品体验更具娱乐性、趣味性、吸引力。我们的技术产品集成在SDK中，为您的二次开发所用，让您无需顾虑2D图形渲染技术的困扰，更加专注于自己的产品。",
          img: require("@/assets/product/core/核心技术-2d贴纸.gif"),
        },
        {
          title: "3D动态贴纸",
          text: "作为VR应用的核心技术之一，我们的3D图形渲染技术可以为您提供人脸3D模型特效、VR场景特效，3D新媒体等技术产品，让您可以轻松使用我们的3D图形渲染技术产品为您的产品增光添彩、驾驭更多新奇瑰丽的想法。",
          img: require("@/assets/product/core/核心技术-3d贴纸.gif"),
        },
      ],
      selectId: 0, //导航选中
      //右侧显示数据
      showItem: null,
      // 技术特色
      feaList: [
        {
          title: "技术成熟",
          img: require("@/assets/product/core/icon_tese_chengshu@2x.png"),
          text: "我们成熟的技术团队始终保持一颗赤诚之心，为用户提供成熟的技术产品。现有的技术产品已经经过广泛的测试",
        },
        {
          title: "稳定高效",
          img: require("@/assets/product/core/icon_tese_wending@2x.png"),
          text: "我们每一项模式识别应用都是经过成千上完次的技术测试和实际测试，保障稳定高效的应用体验",
        },
        {
          title: "持续优化",
          img: require("@/assets/product/core/icon_tese_chixu@2x.png"),
          text: "我们会不断更新现有技术并研发更多的模式识别应用。您可以享受到免费的技术产品升级服务，获得更优的技术产品",
        },
        {
          title: "优质支撑",
          img: require("@/assets/product/core/icon_tese_youzhi@2x.png"),
          text: "我们的后期技术支撑团队可以为您的模式识别应用提供高效优质的技术服务，让您轻松接入我们的技术产品并及时解决相应技术困扰",
        },
      ],
    };
  },
  created() {
    this.toShow();
  },
  watch: {
    $route() {
      this.toShow();
    },
  },
  methods: {
    // 接收跳转显示
    toShow() {
      let index = this.$route.query.id;
      let item = this.leftList[index];
      this.show(item, index);
    },
    //点击显示
    show(item, index) {
      this.selectId = index;
      this.showItem = item;
      /* bus.$emit('getIndex',index) */
    },
  },
};
</script>
<style lang="scss" scoped>
$width: 79.1%;
//中间内容部分
.conter {
  width: $width;
  margin: auto;

  //列表部分
  .nav_content {
    display: flex;
    .left {
      margin-right: 254px;
      font-size: 14px;
      font-weight: 400;
      color: #999;
      li {
        height: 36px;
        line-height: 36px;
        cursor: pointer;
      }
      li:hover {
        color: #4c8cf5;
      }
      .select_li {
        width: 125px;
        text-align: center;
        font-size: 16px;
        color: #4c8cf5;
        background: url("~@/assets/product/icon_product_sel@2x.png") no-repeat 0
          center;
        background-size: 11px 17px;
        vertical-align: middle;
      }
    }
    .right {
      width: 69.2%;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      h3 {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        font-weight: 500;
        color: #222222;
      }
      p {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        margin: 20px 0;
      }
      .imgBox {
        width: 100%;
        overflow: hidden;
        margin-bottom: 80px;
        img {
          width: 100%;
          transition: all 1s;
        }
        img:hover {
          transform: scale(1.2);
        }
      }
    }
  }
}
// 技术特色部分
.features {
  .fea_item {
    width: $width;
    margin: auto;
    font-weight: 400;
    text-align: center;
    display: flex;
    justify-content: space-around;
    margin-bottom: 90px;
    .item {
      width: 19.2%;
      img {
        width: 27.2%;
      }
      h3 {
        height: 52px;
        font-size: 16px;
        color: #222222;
        line-height: 52px;
      }
      p {
        width: 98%;
        height: 73px;
        font-size: 14px;
        color: #999999;
        line-height: 20px;
      }
    }
  }
}
</style>